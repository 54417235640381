/** @jsx jsx */
import { keyframes } from '@emotion/react'
import { Divider, jsx } from 'theme-ui'
import CloudflareDesignLogo from '../components/CloudflareDesignLogo'
import TypographyImage from '../components/TypographyImage'
import Card from '../components/Card'
import Layout from '../layouts/index'
import { variables } from '@cloudflare/style-const'

const rotateHue = keyframes`
  from {
    filter: hue-rotate(-40deg);
    -webkit-filter: hue-rotate(-40deg);
  }
  to {
    filter: hue-rotate(0deg);
    -webkit-filter: hue-rotate(0deg);
  }`

// markup
const Home = () => {
  return (
    <Layout footerType='overview' isHome>
      <div
        sx={{
          marginTop: [3, 3, 6]
        }}
      >
        {/* Hero */}
        <div
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'space-between',
            marginBottom: [3, 3, 6]
            // minHeight: '32vh'
          }}
        >
          <div sx={{}}>
            <h1
              sx={{
                fontSize: [6, 6, 8],
                fontWeight: 'heading',
                marginBlockEnd: 0,
                marginBlockStart: 0
              }}
            >
              Cloudflare Style
            </h1>
            <p
              sx={{
                fontSize: 4,
                maxWidth: '64ch'
              }}
            >
              Our Design Language helps us create a seamless experience across
              each customer touchpoint. Use these tools to create
              attention-grabbing campaigns, compelling presentations, and
              elegant interfaces.
            </p>
          </div>

          <div
            sx={{
              minWidth: 300
            }}
          >
            <CloudflareDesignLogo />
          </div>
        </div>
        {/* Card grid */}
        <div
          sx={{
            display: ['grid', 'grid', 'flex'],
            flexDirection: ['column', 'column', 'row'],
            gridTemplateColumns: '1fr',
            alignContent: 'space-between',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            rowGap: '32px',
            // columnGap: '32px',
            px: ['2', '2', '0']
            // minHeight: '48vh'
          }}
        >
          <Card heading='Brand' type='documentation' destination='/brand'>
            TODO — Add description
          </Card>
          <Card heading='Content' type='speech' destination='/content'>
            Content best practices guides all writing at Cloudflare. These
            guidelines define Cloudflare's voice, maintain consistency, and
            ensure content is accessible for everyone.
          </Card>
          <Card heading='Primitives' type='ok' destination='/primitives'>
            The standards of the visual cues used to create our interfaces. The
            standards outlined cover color, elevation, spacing, and typography.
          </Card>
          <Card heading='Components' type='wrench' destination='/components'>
            TODO — Add description
          </Card>
          <Card heading='Guides' type='list' destination='/guides'>
            Best practice for combining and using the elements available to you
            from Cloudflare Style.
          </Card>
          <Card heading='Resources' type='download' destination='/resources'>
            A collection of tools, kits, plugins, and guides to help simplify
            the creation process for our users.
          </Card>
        </div>
      </div>

      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          columnGap: '24px'
        }}
      >
        {/* Color card */}
        <div
          sx={{
            animation: `${rotateHue} 4s ease-in-out infinite`,
            animationDirection: 'alternate',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: [3, 3, 6],
            p: 4,
            minHeight: '24vh',
            borderRadius: '24px',
            backgroundImage: `
          radial-gradient(at 40% 20%, hsla(28,100%,74%,1) 0, transparent 50%),  
          radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0, transparent 50%), 
          radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0, transparent 50%),  
          radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0, transparent 50%),  
          radial-gradient(at 80% 100%, hsla(242,100%,70%,1) 0, transparent 50%),  
          radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0, transparent 50%);`
          }}
        >
          <h2
            sx={{
              fontSize: 9,
              fontWeight: 'heading',
              marginBlockEnd: 0,
              marginBlockStart: 0
            }}
          >
            Color
          </h2>
        </div>
        {/* Color body */}
        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: [3, 3, 6],
            p: 4,
            minHeight: '24vh'
            // border: '1px solid red'
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <p
              sx={{
                fontSize: 4,
                maxWidth: '64ch'
              }}
            >
              Our color system is composed of 10 optically balanced scales that
              feature more than 1900 accessible combinations.
            </p>
            <span>
              <a
                href='/primitives/color'
                sx={{
                  textDecoration: 'none',
                  borderBottom: '1px dashed',
                  borderColor: 'decorativeContrast',
                  pb: 1,
                  ':after': {
                    content: `' →'`,
                    color: 'decorativeContrast'
                  },
                  ':hover': {
                    borderBottom: '1px solid',
                    borderColor: 'linkHover',
                    color: 'linkHover',
                    ':after': {
                      color: 'linkHover'
                    }
                  }
                }}
              >
                View Color
              </a>
            </span>
          </div>
        </div>
        {/* Typography body */}
        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: [3, 3, 6],
            p: 4,
            minHeight: '24vh'
            // border: '1px solid red'
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <p
              sx={{
                fontSize: 4,
                maxWidth: '64ch'
              }}
            >
              Guidelines for using typography as a tool for effective
              communication.
            </p>
            <span>
              <a
                href='/primitives/typography'
                sx={{
                  textDecoration: 'none',
                  borderBottom: '1px dashed',
                  borderColor: 'decorativeContrast',
                  pb: 1,
                  ':after': {
                    content: `' →'`,
                    color: 'decorativeContrast'
                  },
                  ':hover': {
                    borderBottom: '1px solid',
                    borderColor: 'linkHover',
                    color: 'linkHover',
                    ':after': {
                      color: 'linkHover'
                    }
                  }
                }}
              >
                View Typography
              </a>
            </span>
          </div>
        </div>
        {/* Typography card */}
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: [3, 3, 6],
            p: 4,
            minHeight: '24vh',
            borderRadius: '24px',
            backgroundColor: 'decorative'
          }}
        >
          <TypographyImage />
        </div>
      </div>
    </Layout>
  )
}

export default Home
