import React from 'react'

const CloudflareDesignLogo = () => {
  return (
    <svg viewBox='0 0 1132 849' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M849.357143 424.636325l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
          fill='#FAAE40'
        ></path>
        <path d='M849.357143 141.636364l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
        <path
          fillOpacity='.1'
          fill='#000'
          d='M849.357143 283.136325l141.5-70.749961v141.5l-141.5 70.749961z'
        ></path>
        <path
          fillOpacity='.2'
          fill='#000'
          d='M849.357143 283.136325l-141.5-70.749961v141.5l141.5 70.749961zM895.344643 194.698864v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
        ></path>
        <path
          d='M707.785714 495.454506l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
          fill='#FAAE40'
        ></path>
        <path d='M707.785714 212.454545l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
        <path
          fillOpacity='.1'
          fill='#000'
          d='M707.785714 353.954506l141.5-70.749961v141.5l-141.5 70.749961z'
        ></path>
        <path
          fillOpacity='.2'
          fill='#000'
          d='M707.785714 353.954506l-141.5-70.749961v141.5l141.5 70.749961zM753.773214 265.517045v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
        ></path>
        <path
          d='M566.214286 566.272688l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
          fill='#F38020'
        ></path>
        <path d='M566.214286 283.272727l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
        <path
          fillOpacity='.1'
          fill='#000'
          d='M566.214286 424.772688l141.5-70.749961v141.5l-141.5 70.749961z'
        ></path>
        <path
          fillOpacity='.2'
          fill='#000'
          d='M566.214286 424.772688l-141.5-70.749961v141.5l141.5 70.749961zM612.201786 336.335227v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
        ></path>
        <g>
          <path
            d='M424.642857 637.09087l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M424.642857 354.090909l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M424.642857 495.59087l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M424.642857 495.59087l-141.5-70.749961v141.5l141.5 70.749961zM470.630357 407.153409v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M283.071429 707.909052l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M283.071429 424.909091l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M283.071429 566.409052l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M283.071429 566.409052l-141.5-70.749961v141.5l141.5 70.749961zM329.058929 477.971591v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M707.785714 353.818143l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#FFF'
          ></path>
          <path d='M707.785714 70.818182l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M707.785714 212.318143l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M707.785714 212.318143l-141.5-70.749961v141.5l141.5 70.749961zM753.773214 123.880682v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M566.214286 424.636325l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#FFF'
          ></path>
          <path d='M566.214286 141.636364l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M566.214286 283.136325l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M566.214286 283.136325l-141.5-70.749961v141.5l141.5 70.749961zM612.201786 194.698864v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M424.642857 495.454506l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M424.642857 212.454545l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M424.642857 353.954506l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M424.642857 353.954506l-141.5-70.749961v141.5l141.5 70.749961zM470.630357 265.517045v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M283.071429 566.272688l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M283.071429 283.272727l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M283.071429 424.772688l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M283.071429 424.772688l-141.5-70.749961v141.5l141.5 70.749961zM329.058929 336.335227v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M141.5 778.727234L0 707.977273v-141.5l141.5 70.749961L283 566.477273v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M141.5 495.727273l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M141.5 637.227234L283 566.477273v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M141.5 637.227234L0 566.477273v141.5l141.5 70.749961zM187.4875 548.789773v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875S99.05 576.2458095 99.05 566.477273v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M141.5 778.727234L0 707.977273v-141.5l141.5 70.749961L283 566.477273v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M141.5 495.727273l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M141.5 637.227234L283 566.477273v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M141.5 637.227234L0 566.477273v141.5l141.5 70.749961zM187.4875 548.789773v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875S99.05 576.2458095 99.05 566.477273v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M566.214286 282.999961l-141.5-70.749961V70.75l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M566.214286 0l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M566.214286 141.499961l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M566.214286 141.499961l-141.5-70.749961v141.5l141.5 70.749961zM612.201786 53.0625V70.75c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875V53.0625c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
        </g>
        <g>
          <path
            d='M990.357143 494.636325l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#FAAE40'
          ></path>
          <path d='M990.357143 211.636364l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M990.357143 353.136325l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M990.357143 353.136325l-141.5-70.749961v141.5l141.5 70.749961zM1036.344643 264.698864v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
          <path
            d='M848.785714 565.454506l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#FAAE40'
          ></path>
          <path d='M848.785714 282.454545l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M848.785714 423.954506l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M848.785714 423.954506l-141.5-70.749961v141.5l141.5 70.749961zM894.773214 335.517045v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
          <path
            d='M707.214286 636.272688l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
            fill='#F38020'
          ></path>
          <path d='M707.214286 353.272727l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
          <path
            fillOpacity='.1'
            fill='#000'
            d='M707.214286 494.772688l141.5-70.749961v141.5l-141.5 70.749961z'
          ></path>
          <path
            fillOpacity='.2'
            fill='#000'
            d='M707.214286 494.772688l-141.5-70.749961v141.5l141.5 70.749961zM753.201786 406.335227v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
          ></path>
          <g>
            <path
              d='M565.642857 707.09087l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M565.642857 424.090909l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M565.642857 565.59087l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M565.642857 565.59087l-141.5-70.749961v141.5l141.5 70.749961zM611.630357 477.153409v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M424.071429 777.909052l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M424.071429 494.909091l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M424.071429 636.409052l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M424.071429 636.409052l-141.5-70.749961v141.5l141.5 70.749961zM470.058929 547.971591v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M848.785714 423.818143l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#FFF'
            ></path>
            <path d='M848.785714 140.818182l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M848.785714 282.318143l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M848.785714 282.318143l-141.5-70.749961v141.5l141.5 70.749961zM894.773214 193.880682v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M707.214286 494.636325l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#FFF'
            ></path>
            <path d='M707.214286 211.636364l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M707.214286 353.136325l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M707.214286 353.136325l-141.5-70.749961v141.5l141.5 70.749961zM753.201786 264.698864v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M565.642857 565.454506l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M565.642857 282.454545l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M565.642857 423.954506l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M565.642857 423.954506l-141.5-70.749961v141.5l141.5 70.749961zM611.630357 335.517045v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M424.071429 636.272688l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M424.071429 353.272727l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M424.071429 494.772688l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M424.071429 494.772688l-141.5-70.749961v141.5l141.5 70.749961zM470.058929 406.335227v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M282.5 848.727234L141 777.977273v-141.5l141.5 70.749961L424 636.477273v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M282.5 565.727273l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M282.5 707.227234L424 636.477273v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M282.5 707.227234L141 636.477273v141.5l141.5 70.749961zM328.4875 618.789773v17.6875c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
          <g>
            <path
              d='M707.214286 352.999961l-141.5-70.749961v-141.5l141.5 70.749961 141.5-70.749961v141.5l-141.5 70.749961zm0-282.999961l141.5 70.75-141.5 70.75-141.5-70.75 141.5-70.75z'
              fill='#F38020'
            ></path>
            <path d='M707.214286 70l141.5 70.75-141.5 70.75-141.5-70.75z'></path>
            <path
              fillOpacity='.1'
              fill='#000'
              d='M707.214286 211.499961l141.5-70.749961v141.5l-141.5 70.749961z'
            ></path>
            <path
              fillOpacity='.2'
              fill='#000'
              d='M707.214286 211.499961l-141.5-70.749961v141.5l141.5 70.749961zM753.201786 123.0625V140.75c0 9.7685365-19.797409 17.6875-44.21875 17.6875s-44.21875-7.9189635-44.21875-17.6875v-17.6875c0 9.7685365 19.797409 17.6875 44.21875 17.6875s44.21875-7.9189635 44.21875-17.6875z'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CloudflareDesignLogo
