/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Icon } from '@cloudflare/component-icon'
import { Link } from 'gatsby'

const Card = ({ heading, children, type, destination }) => {
  return (
    <Link
      to={destination}
      sx={{
        textDecoration: 'none'
      }}
    >
      <div
        sx={{
          width: ['auto', 'auto', '400px'],
          height: '272px',
          borderRadius: '8px',
          color: 'text',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          transition: 'background 0.2s, color 0.2s ease',
          h2: {
            ':after': {
              content: `' →'`
            }
          },
          ':hover': {
            backgroundColor: 'blue',
            color: 'background',
            h2: {
              span: {
                borderBottom: '1px solid'
              }
            }
          }
        }}
      >
        <Icon type={type.toLowerCase()} size={32} />
        <h2
          sx={{
            marginBlockEnd: 0
          }}
        >
          <span
            sx={{
              borderBottom: '1px dashed',
              borderColor: 'decorativeContrast'
            }}
          >
            {heading}
          </span>
        </h2>
        <p>{children}</p>
      </div>
    </Link>
  )
}

export default Card
